/* eslint-disable @typescript-eslint/no-duplicate-enum-values */

export interface ApiResponseType<T> {
  code: number
  data: T
  message: string
  timezone?: string
  total_count?: number
  next_page_token?: string
}

// @reference: https://github.com/myfunnow/funnow-go/blob/master/lib/errors/errors.go
export enum ResponseCode {
  SUCCESS = 0,
  INVALID_PARAMETERS = 10,
  /**
   * Need Redirect to Login
   */
  UNAUTHORIZED = 11,
  /**
   * 找不到 Token
   * 可能是不存在、已被刪除或是過期
   */
  UNREGISTERED = 16,
  NOT_FOUND = 16,
  DUPLICATE_KEY = 17,
  CODE_PROMO_DUPLICATE = 200,
  WRONG_OLD_PASSWORD = 32,
  /** 方案已賣完 */
  PRODUCT_SOLD_OUT = 42,
  INVALID_HEADERS = 66,
  /** 刪除已經不存在的圖片 */
  IMAGE_NOT_FOUND = 84,
  WRONG_ACCOUNT_OR_PASSWORD = 100,
  PHONE_NOT_VERIFIED = 105,
  /** 已領過 */
  PROMO_DUPLICATE = 200,
  /** 不符合使用條件 */
  PROMO_LIMIT_MEMBER_TAG = 201,
  /** 領取次數額滿 */
  PROMO_LIMIT_GROUP = 202,
  /** 領取次數額滿 */
  PROMO_LIMIT_CNT = 203,
  /** 領取/使用時間過期 */
  PROMO_INPUT_TIME_EXPIRED = 204,
  CANT_USE_FUN_COIN = 215,
  /** 訂單已兌換 */
  IS_REDEEM = 303,
  /** 重複時段預訂 */
  DUPLICATE_ORDER = 327,
  NEED_UPDATE = 426,
  INTERNAL_SERVER_ERROR = 500,
  FORBIDDEN = 513,
  /** 空位不足 */
  NOT_ENOUGH_RESOURCE = 1400,
  /** 所選的空位已被佔用或暫停  */
  TABLE_UNAVAILABILITY = 4000,
  /** 所選的空位數或桌位人數不足 */
  NOT_ENOUGH_TABLE_OR_AMOUNT = 4001,
  /** 所選的空位數或桌位人數超出預訂人數 */
  OUT_OF_BOOKING_AMOUNT = 4002,
  PAYMENT_PROMO_INVALID = 4900,
}
